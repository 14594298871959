export default {
  mobile: '(minWidth: 400px)',
  Mobile: '@media (min-width: 400px)',
  phablet : '(min-width: 550px)',
  Phablet : '@media (min-width: 550px)',
  tablet : '(min-width: 750px)',
  Tablet : '@media (min-width: 750px)',
  desktop : '(min-width: 1000px)',
  Desktop : '@media (min-width: 1000px)',
  hd : '(min-width: 1200px)',
  Hd : '@media (min-width: 1200px)'
}