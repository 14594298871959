import css from '../layouts/main.css'
import normalize from '../layouts/normalize.css'

import React from 'react'
import presets from '../themes/presets'
import colors from '../themes/colors'
import fonts from '../themes/fonts'

import facebook from "../media/TheBracesGuy_LandingPage_Button_Facebook.png"
import instagram from "../media/TheBracesGuy_LandingPage_Button_Insta.png"
import spotify from "../media/TheBracesGuy_LandingPage_Button_Spotify.png"
import tiktok from "../media/TheBracesGuy_LandingPage_Button_TikTok.png"
import youtube from "../media/TheBracesGuy_LandingPage_Button_YouTube.png"
import Logo from "../media/TheBracesGuy_LandingPage_Logo.png"
import MainPic from "../media/TheBracesGuy_LandingPage_Pic.png"
import merch from "../media/TheBracesGuy_LandingPage_Button_Merch.png"
import requestAppt from '../media/TheBracesGuy_LandingPage_Button_Appointment.png'

import MainHelmet from '../components/base/mainHelmet'

const BASE_URL = 'https://www.thebracesguy.com/'

const styles = {
  page: {
    backgroundColor: 'black',
    display: 'flex',
    flex: 1,
  },
  container: {
    display: 'flex',
    flex: 1,
    width: '100%',
    maxWidth: '1000px',
    margin: '0 auto',
    paddingBottom: '20px',
  },
  horizonalImage: {
    width: '100%',
    marginBottom: '10px',
    [presets.Desktop]: {
      marginBottom: '20px',
    },
  },
  image: {
    width: '100%',
    maxWidth: '300px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    borderBottom: '0 !important',
    textDecoration: 'none',
    backgroundColor: colors.lightGrey,
    color: "black",
    marginBottom: '10px',
  },
  logo: {
    backgroundColor: '#000 !important',
    width: '100%',
    maxWidth: '200px',
    marginBottom: '20px',
    marginTop: '20px',
    [presets.Desktop]: {
      marginBottom: '20px',
      marginTop: '20px',
    },
  },
  link: {
    cursor: 'pointer',
    backgroundColor: '#000',
    paddingLeft: '30px',
    paddingRight: '30px',
  },
  textContainer: {
    color: 'white',
    ...fonts.normal,
    marginTop: '20px',
    padding: '20px',
    fontSize: '1.2em',
    lineHeight: '1.5em',
    textAlign: 'center',
  },
  link: {
    color: colors.brightBlue,
    fontWeight: 'bold',
  },
  flexCenter: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '40px',
    marginBottom: '40px'
  }
}

const socialConfig = {
    tiktok: "https://www.tiktok.com/@thebracesguy",
    instagram: "https://www.instagram.com/thebracesguy",
    facebook: "http://www.facebook.com/rochesterorthodontics",
    spotify: "http://CollinsMixedTape.com",
    youtube: "https://www.youtube.com/channel/UCQnIqABV7z6Cqn3c851PJMA",
    merch: "https://shopcollinsortho.com/collections/the-braces-guy-collection",
}

const links = [
  {
    'image': tiktok,
    'link': socialConfig.tiktok,
  },
  {
    'image': instagram,
    'link': socialConfig.instagram,
  },
  {
    'image': facebook,
    'link': socialConfig.facebook,
  },
  {
    'image': spotify,
    'link': socialConfig.spotify,
  },
  {
    'image': youtube,
    'link': socialConfig.youtube,
  },
  {
    'image': merch,
    'link': socialConfig.merch,
  },
]

const Link = ({link, image }) => {
  return (
    <a
      css={styles.link}
      href={link}
      target="_blank"
    >
      <img css={styles.image} src={image} />
    </a>
  )
}

export default (props) => {
  const renderLinks = () => {
    return links.map(link => {
      return <Link {...link} key={link.link} css={props.css}/>
    })
  }

  return(
    <div css={styles.page}>
      <div css={styles.container}>
        <MainHelmet
          description={'Dr. Grant Collins, better known as The Braces Guy ® (@thebracesguy), is an expert in providing fun and educational braces videos through a variety of social media channels and online platforms.'}
          title={`@TheBracesGuy | Dr. C`}
          image={BASE_URL + Logo}
        />
        <div css={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%'}}>
          <div>
            <img src={Logo} css={[styles.logo, ]}/>
          </div>
          <div>
            <img src={MainPic} css={styles.horizonalImage} />
          </div>
          {renderLinks()}
          <div css={styles.textContainer}>
            <p>
              Dr. Grant Collins, better known as The Braces Guy ® (<a target='_blank' css={styles.link} href={socialConfig.instagram}>@thebracesguy</a>), is an expert in providing fun and educational braces videos through a variety of social media channels and online platforms.
              Via{` `}
              <a target='_blank' css={styles.link} href={socialConfig.instagram}>Instagram</a>,{` `}
              <a target='_blank' css={styles.link} href={socialConfig.facebook}>Facebook</a>,{` `}
              <a target='_blank' css={styles.link} href={socialConfig.youtube}>YouTube</a>,{` `}
              <a target='_blank' css={styles.link} href={socialConfig.spotify}>Spotify</a>{` `}
              and <a target='_blank' css={styles.link} href={socialConfig.tiktok}>TikTok</a>,
              {` `}The Braces Guy ® has over 3 million subscribers, and over 100 million likes, shares and comments.
              He has become best known for his "how to" and "braces colors" videos on TikTok and Instagram, where he demonstrates how to make retainers, how to place and remove braces, and showing different braces color patterns based off of popular tv shows, movies, and cool designs.
            </p>
            <p>
              Dr. Collins is also an orthodontist, philanthropist, author and the founder/owner of <a target='_blank' css={styles.link} href="https://www.rochesterorthodontics.com">Collins Orthodontics</a> in Rochester, Minnesota, where he maintains his private practice.
            </p>

          </div>
          <div css={styles.textContainer}>
            <h3>
              CONTACT:
            </h3>
            <div css={styles.flexCenter}>
              <Link
                link="http://www.requestmyappointment.com"
                image={requestAppt}
              />
            </div>
            <div>
              <strong>Email:</strong> {` `}<a href="mailto:TheBracesGuyOfficial@gmail.com">TheBracesGuyOfficial@gmail.com</a> {` `}(Business Inquiries Only)
            </div>
            <div>
              <strong>Fan Mail: </strong> {` `}Dr. Grant Collins, 2946 Jeremiah Ln NW, Rochester, MN 55901
            </div>
            <div>
              <strong>Orthodontic Consultations: </strong> {` `}<a href="https://www.RochesterOrthodontics.com">RochesterOrthodontics.com</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )

}